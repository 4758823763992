.uppy-DragDrop--is-dragdrop-supported {
    border: 2px dashed !important;
    border-color: #adadad !important;
}
.uppy-DragDrop-inner {
    padding: 20px 20px !important;
    width: 100;
}
.uppy-StatusBar:not(.is-waiting) {
    margin-top: 10px;
}

.uppy-Root {
    margin-top: 15px !important;
}
.uploaded-image {
    border-radius: 5px;
    cursor: pointer;
}